<template>
  <div class="main-wrapper" v-loading="!loaded"
       element-loading-text="正在验证授权"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <router-view v-if="isAuthored"/>
  </div>
</template>

<script>
import {getPreInspectResult} from '@/api/oem';
import {getProvince} from "@/api/api";


export default {
  name: 'oemGuardV2',
  data() {
    return {
      loaded: false,
      isAuthored: false
    };
  },
  components: {},
  async mounted() {
    let data = null;
    if(this.$route.query.version == 3) {
      data = {
        companyName: this.$route.query.companyName,
        companyNo: this.$route.query.companyNo,
        companyTaxNo: this.$route.query.companyTaxNo,
        hydm: this.$route.query.hydm,
        preInspectId: this.$route.query.preInspectId
      }
    } else {
      data = JSON.parse(this.$route.query.info);
    }
    this.$store.commit('oem/setAuth', data);
    this.$store.commit('oem/setZq', this.$route.params.zq);

    this.loaded = true;
    this.isAuthored = true;
  }
};
</script>
<style lang="stylus" scoped>
.main-wrapper {
//   min-height 100vh
}
</style>
