var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: !_vm.loaded,
          expression: "!loaded",
        },
      ],
      staticClass: "main-wrapper",
      attrs: {
        "element-loading-text": "正在验证授权",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [_vm.isAuthored ? _c("router-view") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }